<template>
  <div id="home" class="home p-6">
    <h1 class="text-2xl md:text-4xl font-black mt-6 mb-10">
      Arti e mestieri nella maremma dei musei
    </h1>

    <era-tiles></era-tiles>
  </div>
</template>

<script>
import EraTiles from "@/components/EraTiles.vue";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: { EraTiles },
};
</script>

<style scoped>
.image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
