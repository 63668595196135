<template>
  <div :class="'tile grow cursor-pointer ' + (tile.class || '')">
    <a :href="tile.page">
      <div class="tile-primary-content p-0">
        <template v-if="tile.imageUrl != ''">
          <img
            class="bg-white h-full"
            :src="tile.imageUrl"
            :alt="tile.imageAlt"
          />
        </template>
      </div>
      <div
        class="tile-secondary-content opacity-100 hover-opacity-100 lg:opacity-0 bg-black/0"
      >
        <h2 v-if="false" class="">Something</h2>
        <p
          class="text-2xl absolute left-1/2 top-1/2 transform translate-x-[-50%] translate-y-[-50%] bg-black/70 p-4 font-bold"
        >
          {{ tile.title }}
        </p>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "EraTile",
  components: {},
  props: {
    tile: Object,
  },
};
</script>

<style scoped lang="css">
.tile {
  overflow: hidden;
  padding: 0 1px 1px 0;
  min-width: 320px;
  aspect-ratio: 1 / 1;
}
.tile:focus a::after,
.tile:hover a::after {
  opacity: 1;
  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.tile:focus .tile-primary-content img,
.tile:hover .tile-primary-content img {
  -moz-transform: scale3d(1.1, 1.1, 1.1);
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -moz-transition: -moz-transform 1500ms;
  -o-transition: -o-transform 1500ms;
  -webkit-transition: -webkit-transform 1500ms;
  transition: transform 1500ms;
}
.tile:focus p,
.tile:focus h2,
.tile:focus .tile-secondary-content,
.tile:hover p,
.tile:hover h2,
.tile:hover .tile-secondary-content {
  opacity: 1;
}
.tile a:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.tile .tile-secondary-content {
  -moz-transition: opacity 100ms ease-out;
  -o-transition: opacity 100ms ease-out;
  -webkit-transition: opacity 100ms ease-out;
  transition: opacity 100ms ease-out;
}

a,
button {
  color: white;
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
}
.tile-primary-content,
.tile-secondary-content {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}
.tile-primary-content {
  background-color: #a49058;
}
.tile-primary-content img {
  width: 100%;
  -moz-transform: scale3d(1, 1, 1);
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -moz-transition: -moz-transform 200ms ease-out;
  -o-transition: -o-transform 200ms ease-out;
  -webkit-transition: -webkit-transform 200ms ease-out;
  transition: transform 200ms ease-out;
}
.tile-secondary-content {
  -moz-transition: opacity 250ms ease-out;
  -o-transition: opacity 250ms ease-out;
  -webkit-transition: opacity 250ms ease-out;
  transition: opacity 250ms ease-out;
}
.tile-secondary-content p,
.tile-secondary-content h2 {
  -moz-transition: opacity 200ms ease-in 100ms;
  -o-transition: opacity 200ms ease-in 100ms;
  -webkit-transition: opacity 200ms ease-in;
  -webkit-transition-delay: 100ms;
  transition: opacity 200ms ease-in 100ms;
}
p,
h2 {
  text-transform: uppercase;
}
h2 {
  font-size: 12px;
  margin: 0;
  position: absolute;
}
</style>
