import { createApp } from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";
import "./assets/pannellum.css";
import "./assets/pannellum.js";
import router from "./router";
import "typeface-roboto/index.css";
import { vfmPlugin } from "vue-final-modal";

createApp(App).use(router).use(vfmPlugin).mount("#app");
