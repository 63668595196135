<template>
  <div>
    <!-- <h2 class="text-2xl font-bold uppercase">Esplora Le Ere</h2> -->
    <div
      class="flex mt-12 flex-row justify-center flex-wrap m-auto"
      style="max-width: 1200px; flex: 1 0 auto"
    >
      <era-tile v-for="(tile, index) in tiles" :key="index" :tile="tile"></era-tile>
    </div>
  </div>
</template>

<script>
import EraTile from "./EraTile.vue";
export default {
  name: "EraTiles",
  components: { EraTile },
  setup() {
    const tiles = [
      {
        imageUrl:
          "https://cdna.artstation.com/p/assets/images/images/060/980/698/small/acas3d-protostoria-quadrato.jpg",
        imageAlt: "Protostoria",
        title: "Protostoria",
        page: "/protostoria",
      },
      {
        imageUrl:
          "https://cdnb.artstation.com/p/assets/images/images/061/016/443/medium/acas3d-etrusca-quadrato.jpg",
        imageAlt: "Epoca Etrusca",
        title: "Epoca Etrusca",
        page: "/etrusca",
      },
      {
        imageUrl:
          "https://cdna.artstation.com/p/assets/images/images/061/028/020/small/acas3d-romana-quadrata.jpg",
        imageAlt: "Epoca Romana",
        title: "Epoca Romana",
        page: "/romana",
      },
      {
        imageUrl:
          "https://cdnb.artstation.com/p/assets/images/images/061/028/829/small/acas3d-medioevo-rinascimento-quadrata.jpg",
        imageAlt: "Medioevo e Rinascimento",
        title: "Medioevo e Rinascimento",
        page: "/medioevo",
      },
      {
        imageUrl:
          "https://cdnb.artstation.com/p/assets/images/images/061/029/119/small/acas3d-moderna-quadrato.jpg",
        imageAlt: "Epoca Moderna",
        title: "Epoca Moderna",
        class: "bg-white lg:order-2",
        page: "/moderna",
      },
      {
        imageUrl: "https://arti-e-mestieri.s3.eu-west-3.amazonaws.com/logo.png",
        imageAlt: "Maremma Musei Logo",
        title: "La Mostra",
        class: "bg-white lg:order-1",
        page: "/mostra",
      },
    ];
    return { tiles };
  },
};
</script>
