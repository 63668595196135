<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->

  <nav class="sticky top-0 z-50 shadow-md bg-white">
    <div class="flex pl-4 flex-wrap items-center justify-between mx-auto">
      <a href="/" class="items-center flex relative p-3">
        <img
          src="https://arti-e-mestieri.s3.eu-west-3.amazonaws.com/logo.png"
          alt="Logo"
          class="float-left pl-3"
          style="width: 88px; height: 70px"
        />
        <i v-if="currentRoute != '/'" class="arrow-left"></i>
        <b class="ml-2">ARTI E MESTIERI</b>
      </a>

      <button
        class="text-gray-500 w-10 h-10 relative focus:outline-none bg-white mr-4"
        @click="menuOpen = !menuOpen"
      >
        <span class="sr-only">Open main menu</span>
        <div
          class="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
        >
          <span
            aria-hidden="true"
            class="block absolute h-0.5 w-5 bg-black transform transition duration-500 ease-in-out"
            :class="{ 'rotate-45': menuOpen, ' -translate-y-1.5': !menuOpen }"
          ></span>
          <span
            aria-hidden="true"
            class="block absolute h-0.5 w-5 bg-black transform transition duration-500 ease-in-out"
            :class="{ 'opacity-0': menuOpen }"
          ></span>
          <span
            aria-hidden="true"
            class="block absolute h-0.5 w-5 bg-black transform transition duration-500 ease-in-out"
            :class="{ '-rotate-45': menuOpen, ' translate-y-1.5': !menuOpen }"
          ></span>
        </div>
      </button>

      <Transition>
        <div
          v-show="menuOpen"
          class="absolute right-0 top-[94px] bg-white text-xl border-x-2 w-full md:w-auto z-10 shadow-md"
        >
          <a href="/protostoria" class="border-y-2 p-4 block hover:bg-gray-200"
            >Protostoria</a
          >
          <a href="/etrusca" class="border-b-2 p-4 block hover:bg-gray-200"
            >Epoca Etrusca</a
          >
          <a href="/romana" class="border-b-2 p-4 block hover:bg-gray-200"
            >Epoca Romana</a
          >
          <a href="/medioevo" class="border-b-2 p-4 block hover:bg-gray-200"
            >Medioevo e Rinascimento</a
          >
          <a href="/moderna" class="border-b-2 p-4 block hover:bg-gray-200"
            >Epoca Moderna</a
          >
          <a href="/mostra" class="border-b-2 p-4 block hover:bg-gray-200"
            >La Mostra</a
          >
        </div>
      </Transition>
    </div>
  </nav>

  <router-view />
</template>

<script>
export default {
  name: "App",
  components: {},
  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },
  data() {
    return {
      menuOpen: false,
    };
  },
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

body {
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: white;
}

iframe {
  width: 100%;
  height: 100%;
}
#app {
  font-family: "PT Sans", "Helvetica Neue", Helvetica, "Roboto", Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  color: #333;
  /* color: rgb(241, 241, 241); */
}
.arrow-left {
  border: solid rgb(0, 0, 0);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  position: absolute;
  margin-top: 5px;
}

.work-sans {
  font-family: "Work Sans", sans-serif;
}

#menu-toggle:checked + #menu {
  display: block;
}

.hover\:grow {
  transition: all 0.3s;
  transform: scale(1);
}

.hover\:grow:hover {
  transform: scale(1.02);
}

.carousel-menuOpen:checked + .carousel-item {
  position: static;
  opacity: 100;
}

.carousel-item {
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}

#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}

.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}

#carousel-1:checked
  ~ .control-1
  ~ .carousel-indicators
  li:nth-child(1)
  .carousel-bullet,
#carousel-2:checked
  ~ .control-2
  ~ .carousel-indicators
  li:nth-child(2)
  .carousel-bullet,
#carousel-3:checked
  ~ .control-3
  ~ .carousel-indicators
  li:nth-child(3)
  .carousel-bullet {
  color: #000;
  /*Set to match the Tailwind colour you want the active one to be */
}
</style>
