import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/protostoria",
    name: "protostoria",
    component: () => import("../views/ProtostoriaView.vue"),
  },
  {
    path: "/etrusca",
    name: "etrusca",
    component: () => import("../views/EtruscaView.vue"),
  },
  {
    path: "/romana",
    name: "romana",
    component: () => import("../views/RomanaView.vue"),
  },
  {
    path: "/medioevo",
    name: "medioevo",
    component: () => import("../views/MedioevoView.vue"),
  },
  {
    path: "/moderna",
    name: "moderna",
    component: () => import("../views/ModernaView.vue"),
  },
  {
    path: "/mostra",
    name: "mostra",
    component: () => import("../views/MostraView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
